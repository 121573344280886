/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import AuctionItem from './buyer/AuctionItem';

const SoldMachinery = ({ machines, classNameForBG }) => (
  <section className={classNameForBG}>
    <div className="container">
      <div className="row">
        <div className="col">
          <h2 className="section__header__title section__header__title--mb-45 text-center">Satışı Gerçekleşen Makinalar</h2>
        </div>
      </div>
      <div className="row row--mb30">
        <div className="col-15">
          <div className="row justify-content-center">
            {
              _.map(machines, (machine) => (
                <AuctionItem machine={machine} key={machine.id} />
              ))
            }
          </div>
        </div>
      </div>
    </div>
  </section>
);

SoldMachinery.propTypes = {
  machines: PropTypes.array,
  classNameForBG: PropTypes.string,
};

export default SoldMachinery;
